





.transition {
    transition: background-color 0.4s ease, color 0.4s ease, font-weight 0.4s ease;
}

.hover-btn {
    background-color: #939393;
    transition: box-shadow 0.4s ease;
    
    &:hover {
        background-color: #F0732C;
        box-shadow: 0px 0px 30px #F0732C;
    }
}





